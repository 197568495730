import React from 'react';
import { Link } from 'gatsby';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded';
// import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';

import '../styles/header.css';

const Header = () => {
    return (
        <nav className="navbar">
            <div className="navbar__left">
                <Link to="/" className="nav__item brand"><HomeRoundedIcon />Anson Varughese</Link>
            </div>
            <div className="navbar__right">
                <Link to="/" className="nav__item aboutme"><AssignmentIndRoundedIcon />About Me</Link>
                {/* <Link to="/blog" className="nav__item blog"><MenuBookRoundedIcon />Blog</Link> */}
            </div>
        </nav>
    )
}

export default Header